import { localStorage } from "@jog/shared";
import { useRouter } from "next/router";
import { useEffect } from "react";
export const useAwin = () => {
    const router = useRouter();
    useEffect(() => {
        if (router.isReady) {
            const awc = router.query.awc;
            if (awc) {
                const t = new Date().getTime();
                localStorage.setItem("awin_awc", { awc, t });
            }
        }
    }, [router.isReady, router.query.awc]);
};
export const getAwinAwc = () => {
    if (!process.env.NEXT_PUBLIC_AWIN_ADVERTISER_ID)
        return;
    try {
        const data = localStorage.getItem("awin_awc");
        if (!data)
            return;
        const now = new Date().getTime();
        if (now - data.t > 1000 * 60 * 60 * 24 * 30) {
            localStorage.removeItem("awin_awc");
            return;
        }
        return { awc: data.awc, advertiserId: process.env.NEXT_PUBLIC_AWIN_ADVERTISER_ID };
    }
    catch (e) {
        console.error(e);
        return;
    }
};
